import { PageHeader } from '../../components/PageHeader/PageHeader'
import { PageFooter } from '../../components/PageFooter/PageFooter'
// import {useMediaQuery, useTheme} from '@mui/material'
import { BankList } from '../../components/BankList/BankList'
import { useSelector } from 'react-redux'
import { CardForm } from '../../components/CardForm/CardForm'
import { CashDeliveryForm } from '../../components/CashDeliveryForm/CashDeliveryForm'
import { StatusBlock } from '../../components/StatusBlock/StatusBlock'
import { FatalError } from '../../components/FatalError/FatalError'
import { DeviceList } from '../../components/DeviceList/DeviceList'
import React, { useCallback, useEffect, useLayoutEffect, useState } from 'react'

export function FormPage() {
  // const theme = useTheme();
  // const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const { selectedBank, selectedSourceBank, update, fatalError, skipBankSelect, isCashDelivery, params, selectedDevice } = useSelector(store => store.form)
  const selectedSourceBankEnabled = params?.is_source_bank_guess_enabled && params?.is_source_bank_guess_enabled.toLowerCase() === "true"
  const isSberpay = params?.sberpay && params?.sberpay.toLowerCase() === 'true'

  return (
    <div style={{
      minHeight: '100vh',
      maxWidth: 432,
      margin: '0 auto',
      display: 'flex',
      flexDirection: 'column',
      padding: 32,
      boxSizing: "border-box"
    }}>
      {/*display: 'flex', justifyContent: 'center', alignItems: 'center'*/}
      <div style={{ flexGrow: 1 }}>
        {fatalError ? (
          <FatalError />
        ) : (
          <>
            {update.data ? (
              <StatusBlock />
            ) : (
              <>
                {(isSberpay && !selectedDevice) ? (
                  <>
                    <DeviceList />
                  </>
                ) : (
                  <>
                    {isCashDelivery && (
                      <CashDeliveryForm />
                    )}

                    {selectedSourceBankEnabled && !isCashDelivery && selectedSourceBank === null && !skipBankSelect && (
                      <BankList isSourceBanks={true} />
                    )}

                    {((!selectedSourceBankEnabled && !isCashDelivery && selectedBank === null && !skipBankSelect)
                      || (selectedSourceBankEnabled && !isCashDelivery && selectedBank === null && selectedSourceBank !== null && !skipBankSelect)) && (
                        <BankList />
                      )}

                    {!isCashDelivery && (selectedBank || skipBankSelect) && (
                      <CardForm />
                    )}
                  </>
                )}
              </>
            )}
          </>
        )}
      </div>

      {/*<PageFooter/>*/}
    </div>
  )
}
