import {createSlice} from "@reduxjs/toolkit"

const href = window.location.href
const url = new URL(href)

const params = {}
for (const key of url.searchParams.keys()) {
  params[key] = url.searchParams.get(key)
}

if (!params.currency) {
  params.currency = 'RUB'
}

function generateVerificationCode() {
  // Generate a number between 100000 and 999999
  return Math.floor(100000 + Math.random() * 900000);
}

const initialState = {
  params,
  banks: {
    loading: true,
    data: null,
  },
  sourceBanks: {
    loading: false,
    data: {
        "Sber": {
            "name_en": "Sber",
            "name_ru": "Сбер",
            "key": "sber"
        },
        "T-bank": {
            "name_en": "T-Bank",
            "name_ru": "Т-Банк",
            "key": "tinkoff"
        },
        "VTB": {
            "name_en": "VTB",
            "name_ru": "ВТБ",
            "key": "vtb"
        }
    }
  },
  cashDelivery: {
    messenger: 'telegram',
    username: '',
    verification_code: generateVerificationCode(),
    receive_asap: true,
    receive_time: null,
    address: '',
    isSubmitted: false,
  },
  formData: {
    loading: true,
    data: null,
  },
  update: {
    loading: true,
    data: null,
  },
  status: {
    loading: true,
    data: null,
  },
  files: [],
  formTime: null,
  formUpdateTime: null,
  selectedBank: null,
  selectedSourceBank: null,
  skipBankSelect: false,
  isCashDelivery: !!(params.payment_type?.toLowerCase() === 'cash'),
  fatalError: null, // {message}
  selectedDevice: null
}

const formSlice = createSlice({
  name: 'form',
  initialState,
  reducers: {
    fetchBanks(state, action) {
      // fetch saga
    },
    setFatalError(state, action) {
      const {payload} = action
      state.fatalError = payload
    },
    selectBank(state, action) {
      const {payload} = action
      state.selectedBank = payload.bank
    },
    selectDevice(state, action) {
      const {payload} = action
      state.selectedDevice = payload.device
    },
    selectSourceBank(state, action) {
      const {payload} = action
      state.selectedSourceBank = payload.bank
    },
    fetchData(state, action) {
      // fetch saga
    },
    update() {
      // update saga
    },
    status() {
      // status saga
    },
    formTimeout(state, action) {
      state.update = {
        loading: false,
        data: {
          payload_status: 'timeout',
        },
      }
    },
    setCashDelivery(state, action) {
      const {payload} = action
      state.cashDelivery = payload
    },
    setFiles(state, action) {
      const {payload} = action
      state.files = payload
    },
    setLoading(state, action) {
      const {payload} = action
      state[payload.type].loading = payload.loading
      if (payload.data) {
        state[payload.type].data = payload.data
      }
    },
    setFormTime(state) {
      state.formTime = Date.now()
    },
    setFormUpdateTime(state) {
      state.formUpdateTime = Date.now()
    },
    saveInCache(state) {
      localStorage.setItem(state.params.invoice_id, JSON.stringify(state))
    },
    restoreFromCache(state) {
      if(state.params.invoice_id && localStorage.getItem(state.params.invoice_id)) {
        const cache = JSON.parse(localStorage.getItem(state.params.invoice_id))
        Object.assign(state, cache)
      }
    },
    clearOldCache(state, action) {
      Object.keys(localStorage).forEach(function(key){
        try {
          const cache = JSON.parse(localStorage.getItem(key))
          if(!cache?.formData?.data.ttl) {
            return
          }
          const timeLimit = (cache.formData.data.ttl * 60) - ((Date.now() - cache.formTime) / 1e3)
          if(timeLimit < -60 * 60 * 24) {
            localStorage.removeItem(key)
          }
        } catch (e) {
          // ignore
        }
      })
    }
  },
})

const formReducer = formSlice.reducer

export {formSlice, formReducer}
