const paymentTypes = {
  card: {
    key: 'card',
    type: 'card',
    displayType: 'card',
    helpTexts: [
      'card1',
      'card2',
      'card3',
      'card4',
    ],
    sberPay: {
      Android: {
        url: 'intent://ru.sberbankmobile/payments/p2p?type=card_number&requisiteNumber={replace}',
        type: 'account_number',
      },
      iOS: {
        url: 'btripsexpenses://sbolonline/messenger/dialog-for-phone?phone={replace}&tab=payments',
        type: 'phone',
      },
    },
  },
  sbp: {
    key: 'sbp',
    type: 'sbp',
    displayType: 'sbp',
    helpTexts: [
      'sbp1',
      'sbp2',
      'sbp3',
      'sbp4',
    ],
    sberPay: {
      Android: {
        url: 'intent://ru.sberbankmobile/payments/p2p?type=card_number&requisiteNumber={replace}',
        type: 'account_number',
      },
      iOS: {
        url: 'btripsexpenses://sbolonline/messenger/dialog-for-phone?phone={replace}&tab=payments',
        type: 'phone',
      },
    }
  },
  $sberPay: {
    key: 'sbp',
    type: 'sbp',
    displayType: 'sberPay',
    helpTexts: [
      'sberPay1',
      'sberPay2',
      'sberPay3',
      'sberPay4',
      'sberPay5',
    ],
    helperTextsMobile: [
      'sberPayMobile1',
      'sberPayMobile2',
      'sberPayMobile3',
    ],
    sberPay: {
      Android: {
        url: 'intent://ru.sberbankmobile/payments/p2p?type=card_number&requisiteNumber={replace}',
        type: 'account_number',
      },
      iOS: {
        url: 'btripsexpenses://sbolonline/messenger/dialog-for-phone?phone={replace}&tab=payments',
        type: 'phone',
      },
    }
  },
  walletm10: {
    key: 'WalletM10',
    type: 'phone',
    beneficiary: 'phone',
    helpTexts: [
      'doNotChangeTransferAmount',
      'doNotSpecifyTransferComment',
      'ensureRecipientNameAndBankMatchDetails',
      'paymentMustBeMadeInOneAmount',
      'pressCancelToSelectAnotherBank',
    ],
  },
  local_banks: {
    key: 'local_banks',
    type: 'account',
    beneficiary: 'account',
    helpTexts: [
      'doNotChangeTransferAmount',
      'doNotSpecifyTransferComment',
      'transferToAccountNumberAbove',
      'paymentMustBeMadeInOneAmount',
      'pressCancelToSelectAnotherBank',
    ],
  },
  account: {
    key: 'account',
    type: 'account',
    beneficiary: 'account',
    helpTexts: [
      'doNotChangeTransferAmount',
      'doNotSpecifyTransferComment',
      'transferToAccountNumberAbove',
      'paymentMustBeMadeInOneAmount',
      'pressCancelToSelectAnotherBank',
    ],
    sberPay: {
      Android: {
        url: 'intent://ru.sberbankmobile/payments/p2p?type=card_number&requisiteNumber={replace}',
        type: 'account_number',
      },
      iOS: {
        url: 'btripsexpenses://sbolonline/messenger/dialog-for-phone?phone={replace}&tab=payments',
        type: 'phone',
      },
    }
  }
}

export function getRequisiteType(beneficiary, params = {}) {
  const found = paymentTypes[beneficiary.requisite_type.toString().toLowerCase()]
  if (found && params.sberpay && found.sberPay) {
    return paymentTypes.$sberPay
  }
  if (found) {
    return paymentTypes[beneficiary.requisite_type.toString().toLowerCase()]
  }
}

export function fixRequisiteType(beneficiary) {
  if (beneficiary.requisite_type) {
    const paymentType = paymentTypes[beneficiary.requisite_type.toString().toLowerCase()]
    if (paymentType) {
      return paymentType.key
    }
  }
  if (!beneficiary.requisite_type) {
    if (beneficiary.phone) {
      return 'sbp'
    }
    if (beneficiary.pan) {
      return 'card'
    }
    if (beneficiary.account_number) {
      return 'local_banks'
    }
  }
}
