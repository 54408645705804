import {Box, styled} from '@mui/material'

import {ReactComponent as SecureConnection} from './imgs/secure-connection.svg'
import {ReactComponent as VerifiedByVisa} from './imgs/verified-by-visa.svg'
import {ReactComponent as Mastercard} from './imgs/mastercard.svg'
import {ReactComponent as MirAccept} from './imgs/mir-accept.svg'
// import {ReactComponent as Carnet} from './imgs/carnet.svg'
import {ReactComponent as Humo} from './imgs/humo.svg'
import {ReactComponent as Uzcard} from './imgs/uzcard.svg'
import {ReactComponent as UnionPay} from './imgs/union-pay.svg'
import {ReactComponent as AmericanExpress} from './imgs/american-express.svg'
import {ReactComponent as PciDss} from './imgs/pci-dss.svg'

const CertLogs = styled(Box)`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  animation-duration: 0.35s;
  animation-name: animate-fade;
  animation-delay: 0.35s;
  animation-fill-mode: backwards;

  > svg {
    fill: #808080;
    width: 60px;
    height: 26px;
    margin: 8px;
  }

  svg.wide-1 {
    width: 68px;
    margin: 8px 4px;
  }
  
  svg.wide-2 {
    width: 72px;
    margin: 8px 2px;
  }
`;

const BaseLogo = styled(Box)`
  width: 60px;
  height: 26px;
  background-repeat: no-repeat;
  background-position: 50% 0;
  background-size: contain;
  margin: 8px;
`

// const PCILogo = styled(BaseLogo)`
//   background-image: url(./images/pci-mono.svg);
// `
//
// const VisaLogo = styled(BaseLogo)`
//   background-image: url(./images/visa-mono.svg);
// `
//
// const MastercardLogo = styled(BaseLogo)`
//   background-image: url(./images/mastercard-mono.svg);
// `
//
// const MirLogo = styled(BaseLogo)`
//   background-image: url(./images/mir.svg);
// `

export function PageFooter() {

  return (
    <Box sx={{mt: 2}}>
      {/*<CertLogs>*/}
      {/*  <PCILogo/>*/}
      {/*  <VisaLogo/>*/}
      {/*  <MastercardLogo/>*/}
      {/*  <MirLogo/>*/}
      {/*</CertLogs>*/}

      <CertLogs>
        <PciDss/>
        <SecureConnection className={'wide-1'}/>
        <VerifiedByVisa/>
        <Mastercard/>
        <MirAccept/>
        {/*<Carnet/>*/}
        <Humo/>
        <Uzcard className={'wide-1'}/>
        <AmericanExpress/>
        <UnionPay/>
      </CertLogs>

    </Box>
  )
}
