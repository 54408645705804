import {Provider} from 'react-redux'
import {ThemeProvider} from '@mui/material'
import {FormPage} from './pages/FormPage/FormPage'
import {dispatch, store} from './store/store'
import {formSlice} from './store/form/formSlice'
import "./locale/i18n"
// import FingerprintJS from '@fingerprintjs/fingerprintjs';
// import {useEffect} from 'react'
// import {useTranslation} from "react-i18next";
// {t('key', {name: 'test'})}
// {t('key2.test')}

const theme = {}

function App() {
  return (
    <div className="App">
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <FormPage/>
        </ThemeProvider>
      </Provider>
    </div>
  )
}

dispatch(formSlice.actions.clearOldCache())
dispatch(formSlice.actions.restoreFromCache())

export default App;
