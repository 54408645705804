export const az = {
  common: {
    title: {
      card: 'Kart nömrəsi ilə köçürmə',
      sbp: 'SBP vasitəsilə köçürmə edin',
      sberPay: 'Sberpay vasitəsilə köçürmə edin',
      walletm10: 'Tərcümə edin',
      local_banks: 'Tərcümə edin',
      account: 'Tərcümə edin',
    },
    copied: 'Kopyalandı',
  },
  PageHeader: {
    order: 'Sifarişin nömrəsi {{order}}',
  },
  BankList: {
    selectBank: 'Ödəniş üsulunuzu seçin',
    selectSourceBank: 'Выберите банк, с которого будете переводить средства',
    bankSelectionNotice: 'Bank seçərkən diqqətli olun. Yenidən bank seçə bilməzsiniz.',
  },
  CardForm: {
    amountChangedTitle: 'İlkin məbləğin təfərrüatları müvəqqəti olaraq mövcud deyil. Ödəmənizi təklif edirik',
    amountChangedText: '{{amount}} {{currency}}',
    amountChangedConfirm: 'Davam et',
    sbpPhone: 'Alıcı nömrəsi',
    sbpBank: 'Alıcının bankı',
    sbpFio: 'Alıcının adı',
    remainingTime: 'Tərcümə vaxtı:',
    pan: 'Kartı nömrəsi',
    phone: 'Telefon nömrəsi',
    accountNumber: 'Hesab nömrəsi',
    recipient: 'Qəbul edən',
    uploadTransactionReceipt: 'Tranzaksiya qəbzini yükləyin',
    attention: 'Təlimatlar',
    paymentConfirmed: 'Ödəniş statusunu yoxlayın',
    cancel: 'Ləğv et',
    selectYourMobileDevice: 'Cihazınızı seçin',
    paymentTypes: {
      card: 'Kart nömrəsini istifadə etməklə koçürmə edin',
      phone: 'SBP vasitəsilə telefon nömrəsi ilə köçürmə edin',
      sbp: '',
      walletm10: 'M10 vasitəsilə telefon nömrəsi ilə köçürmə edin',
      local_banks: 'Hesab nömrənizdən istifadə edərək köçürmə edin',
      account: 'Hesab nömrənizdən istifadə edərək köçürmə edin',
    },
    paymentTips: {
      doNotChangeTransferAmount: 'Köçürmə məbləğini dəyişməyin',
      doNotSpecifyTransferComment: 'Tərcümə üçün şərh əlavə etməyin',
      ensureRecipientNameAndBankMatchDetails: 'Alıcının adının və bankın göstərilən rekvizitlərə uyğun olduğundan əmin olun',
      paymentMustBeMadeInOneAmount: 'Ödəniş bir məbləğdə edilməlidir',
      pressCancelToSelectAnotherBank: 'Başqa bank seçmək üçün “Ləğv et” üzərinə klikləyin və ödənişi yenidən yaradın.',
      transferToAccountNumberAbove: 'Yuxarıda göstərilən hesab nömrəsinə köçürmə edin',
      ifUnableToPayPressCancel: 'Ödəniş edə bilmirsinizsə, "Ləğv et" düyməsini basın',
      enterPaymentAmountAndSubmit: 'Tətbiqdə doldurarkən göstərdiyiniz ödəniş məbləğini göstərin və "Göndər" düyməsini basın.',
      card1: 'Göstərilən dəqiq məbləği köçürün',
      card2: 'Köçürmə edərkən kartın nömrəsinin yuxarıda göstərilən nömrəyə uyğun olub olmadığını yoxlayın',
      card3: 'Köçürmədən sonra ödəniş statusunu yoxlayın',
      card4: 'Ödəniş edə bilmirsinizsə, "Ləğv et" düyməsini basın',
      sberPay1: 'Cihazınızı seçin',
      sberPay2: 'QR kodunu skan edin',
      sberPay3: 'Tətbiqdə ödəniş edərkən dəqiq məbləği göstərin',
      sberPay4: 'Köçürmədən sonra ödəniş statusunu yoxlayın',
      sberPay5: 'Ödəniş edə bilmirsinizsə, "Ləğv et" düyməsini basın',
      sberPayMobile1: 'Göstərilən dəqiq məbləği köçürün',
      sberPayMobile2: 'Köçürmədən sonra ödəniş statusunu yoxlayın',
      sberPayMobile3: 'Ödəniş edə bilmirsinizsə, "Ləğv et" düyməsini basın',
      sbp1: 'Göstərilən dəqiq məbləği köçürün',
      sbp2: 'Köçürmə həyata keçirərkən, alıcının adının və bankın yuxarıda göstərilənlərə uyğun olduğunu yoxlayın',
      sbp3: 'Köçürmədən sonra ödəniş statusunu yoxlayın',
      sbp4: 'Ödəniş edə bilmirsinizsə, "Ləğv et" düyməsini basın',
    },
  },
  CashDeliveryForm: {
    enterCourierData: 'Kuryer məlumatlarını daxil edin',
    saveConfirmationCode: 'Doğrulama kodunuzu yadda saxlayın',
    confirmationRequiredForCourier: 'Kuryerlə görüşərkən tələb olunacaq',
    confirmationCodePrompt: 'Təsdiqedici kod:',
    attentionWarning: 'Ehtiyatlı ol!',
    courierContactUsageNotice: 'Göstərilən məlumatlar kuryer tərəfindən sizinlə əlaqə saxlamaq üçün istifadə olunacaq',
    orderAcceptedMessage: 'Təşəkkür edirəm, sifarişiniz qəbul edildi.',
    courierConfirmationMessage: 'Təsdiq üçün kuryerdən mesaj gözləyin.',
    sendCourier: 'Kuryer göndərin',
    label: {
      messenger: 'Messenger',
      phone: 'Telefon nömrəsi',
      login: 'messenger girişi/telefon nömrəsi',
      address: 'Kuryerlə tanış olmaq üçün ünvan',
      immediateDelivery: 'Dərhal çatdırın',
      deliveryDateTime: 'Çatdırılma tarixi və vaxtı',
    },
    error: {
      required: 'Məcburi sahə',
      incorrectNumber: 'Yanlış nömrə',
      incorrectLogin: 'Yanlış giriş',
    },
  },
  StatusBlock: {
    paymentSuccessful: 'Transfer qəbul edildi',
    paymentDeclined: 'Tərcümə tapılmadı',
    returnToShop: 'Sayta qayıt',
    status: {
      somethingWentWrong: 'Nəsə xəta baş verdi.',
      waitingForFunds: 'Vəsaitlərin gəlməsini gözləyirik.',
      operationCancelled: 'Əməliyyat ləğv edilib.',
      paymentTimeExpired: 'Ödəniş vaxtı bitdi.',
    },
    return: {
      returnToShopAndRetryPayment: 'Sayta qayıdıb ödənişi təkrar edə bilərsiniz.',
      returnToShop: 'Sayta qayıda bilərsiniz.',
    },
  },
  Upload: {
    ok: 'Tamam',
    upload: 'Yüklə',
    error: 'Xəta',
  },
  FatalError: {
    somethingWentWrong: 'Nəsə xəta baş verdi.',
    returnToShopAndRetryPayment: 'Sayta qayıdıb ödənişi təkrar edə bilərsiniz.',
    returnToShop: 'Sayta qayıt',
    returnToPaymentMethodSelection: 'Ödəniş metodunun seçiminə qayıdın',
    cancel: 'Ləğv et',
    code: {
      NO_TERMINALS_AVAILABLE: 'Bu ödəniş üsulu üçün heç bir terminal yoxdur.',
      FORM_PAY_ALREADY_SUBMITED: 'Yenidən ödəmək üçün mağazaya qayıdın.',
      COMMON_ERROR: 'Ödəniş zamanı xəta.',
      DUPLICATE_ORDER_ERROR: 'Bu nömrə ilə sifariş artıq mövcuddur.',
      ROUTE_ERROR: 'Bu ödənişi etmək imkanı yoxdur.',
    },
  }
}
