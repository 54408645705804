import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import {Environment} from "../config/Environment";
import {en} from "./en";
import {ru} from "./ru";
import {pt} from "./pt";
import {hu} from "./hu";
import {az} from "./az";
import {uz} from "./uz";

const url = new URL(window.location.href)

let language = Environment.REACT_APP_DEFAULT_LANGUAGE ? Environment.REACT_APP_DEFAULT_LANGUAGE : 'ru'

const lp = url.searchParams.get('language')
if (lp && (lp === 'ru' || lp === 'en' || lp === 'pt' || lp === 'hu' || lp === 'az'  || lp === 'uz')) {
  language = lp
}

i18n
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    lng: language,
    // fallbackLng: Environment.REACT_APP_DEFAULT_LANGUAGE ? Environment.REACT_APP_DEFAULT_LANGUAGE : 'en',
    // debug: true,
    resources: {
      en: {
        translation: {...en},
      },
      ru: {
        translation: {...ru},
      },
      pt: {
        translation: {...pt},
      },
      hu: {
        translation: {...hu},
      },
      az: {
        translation: {...az},
      },
      uz: {
        translation: {...uz},
      }
    }
  });


export default i18n;
